/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useRef, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { useLazyQuery } from '@apollo/client';

import { object, string, number } from 'prop-types';
import mbpLogger from 'mbp-logger';
import Graphq404ErrorProducts from '../GraphqlSearchPage/Partials/GraphqlSearchProducts';
import findCategoryById from '../../../gql/queries/findCategoryById';

import { GRAPHQL_ENV } from '../../../gql';
import { getBrand } from '../../../../state/ducks/App/ducks/Brand/Brand-Selectors';
import useExperimentServiceAttributes from '../../../helpers/experimentService/useExperimentServiceAttributes';

const styles = (theme) => ({
    siteContainer: {
        maxWidth: '1400px',
        [theme.breakpoints.up(1430)]: {
            margin: '0 auto',
        },
        [theme.breakpoints.down(600)]: {
            maxWidth: '100%',
            margin: '0 auto',
        },
    },
});

const determineProductOptions = (pageNumber, pageSize = 12) => ({
    pageSize,
    page: pageNumber,
});

const GraphqlProductContainer = ({
    brand, categoryCode, classes, productsPerRow,
}) => {
    const pageNumber = useRef(1);
    const { targeting, context, isGraphqlTargetingEnabled } = useExperimentServiceAttributes({ queryName: 'findCategoryById' });
    const findCategoryByIdQuery = findCategoryById(isGraphqlTargetingEnabled);
    const [getProducts, {
        loading, error, data, fetchMore, variables,
    }] = useLazyQuery(findCategoryByIdQuery);
    useEffect(() => {
        getProducts({
            variables: {
                brand: brand['domain-name'],
                environment: GRAPHQL_ENV,
                locale: 'en-us',
                id: categoryCode,
                productOptions: determineProductOptions(pageNumber.current, 12),
                ...(isGraphqlTargetingEnabled ? { targeting } : {}),
            },
            context,
        });
    }, []);

    const getMoreResults = () => {
        if (fetchMore) {
            pageNumber.current += 1;
            fetchMore({
                variables: {
                    // only need to define the variables that change in between queries
                    productOptions: determineProductOptions(pageNumber.current, 12),
                    ...(isGraphqlTargetingEnabled ? { targeting } : {}),
                },
                context,
            });
        }
    };

    if (loading) {
        return <div style={{ height: '80vh' }} />; // prevent header and footer from snapping together
    }

    if (error) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            query: findCategoryByIdQuery,
            component: 'Graphql404ErrorProductContainer.js',
            message: 'Error loading data from Graphql',
            env: GRAPHQL_ENV,
            error,
        });
        return null;
    }

    if (!data) {
        mbpLogger.logWarning({
            appName: process.env.npm_package_name,
            query: findCategoryByIdQuery,
            component: 'Graphql404ErrorProductContainer.js',
            message: 'No data returned for query',
            env: GRAPHQL_ENV,
        });
        return null;
    }
    if (data.findCategoryById?.products?.length) {
        const products = data.findCategoryById.products;
        const totalPages = data.findCategoryById?.totalPages;
        return (
            <Grid className={classes.siteContainer}>
                <Graphq404ErrorProducts
                    brand={brand}
                    products={products}
                    eventSubType="404"
                    fetchMoreResults={getMoreResults}
                    totalPages={totalPages}
                    pageNumber={pageNumber.current}
                    productsPerRow={productsPerRow}
                    variables={variables}
                />
            </Grid>
        );
    }
    return null;
};

GraphqlProductContainer.propTypes = {
    categoryCode: string.isRequired,
    brand: object.isRequired,
    classes: object.isRequired,
    productsPerRow: number.isRequired,
};

const mapStateToProps = (state) => ({
    brand: getBrand(state),
});

export default connect(mapStateToProps)(withStyles(styles)(GraphqlProductContainer));
