/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Query } from '@apollo/client/react/components';
import { object } from 'prop-types';
import mbpLogger from 'mbp-logger';

import findURL from '../../../gql/queries/findURL';
import { GRAPHQL_ENV } from '../../../gql';
import * as app from '../../../../state/ducks/App';
import Graphql404ErrorCategoryContainer from './Graphql404ErrorCategoryContainer';

const brandSelectors = app.ducks.brand.selectors;

const Graphql404ErrorCollectionContainer = ({
    brand, pageData,
}) => {
    const {
        collection_url: path,
    } = pageData?.findContentPage?.content?.entries[0];

    const ERROR_PAGE_QUERY = findURL(brand.domain, path);

    return (
        <Query query={ERROR_PAGE_QUERY}>
            {({ loading, error, data }) => {
                if (loading) {
                    return <div style={{ height: '80vh' }} />; // prevent header and footer from snapping together
                }

                if (error) {
                    mbpLogger.logError({
                        appName: process.env.npm_package_name,
                        query: ERROR_PAGE_QUERY,
                        component: 'Graphql404ErrorCollectionContainer.js',
                        message: 'Error loading data from Graphql',
                        env: GRAPHQL_ENV,
                        error,
                    });
                    return null;
                }

                if (!data) {
                    mbpLogger.logWarning({
                        appName: process.env.npm_package_name,
                        query: ERROR_PAGE_QUERY,
                        component: 'Graphql404ErrorCollectionContainer.js',
                        message: 'No data returned for query',
                        env: GRAPHQL_ENV,
                    });
                    return null;
                }

                const collectionContentType = data.findURL.contentType;
                return <Graphql404ErrorCategoryContainer brand={brand} url={path} collectionContentType={collectionContentType} pageData={pageData} />;
            }}
        </Query>
    );
};

Graphql404ErrorCollectionContainer.propTypes = {
    brand: object.isRequired,
    pageData: object.isRequired,
};

const mapStateToProps = (state) => ({
    brand: brandSelectors.getBrand(state),
});

export default withRouter(connect(mapStateToProps)(Graphql404ErrorCollectionContainer));
