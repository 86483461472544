/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { gql } from '@apollo/client';

/**
 * @param {boolean} targetingEnabled - enable or disable targeting in query
 */
const findCategoryById = (targetingEnabled) => (
    // very important for serializing. add space between bracket and query name + add typenames to query
    gql`
        query CategoryProductQuery($productOptions: ProductOptions, $brand: String!, $environment: String!, $id: String!, $locale: String!${targetingEnabled ? ', $targeting: [Targeting]' : ''}) {
            findCategoryById(brand: $brand, environment: $environment, id: $id, locale: $locale, productOptions: $productOptions${targetingEnabled ? ', targeting: $targeting' : ''}) {
                products {
                    id
                    productSkus {
                        id
                      }
                    brandId
                    image {
                        additionalImages
                        altText
                        name
                        path
                        snipe
                        snipeImagePath
                        __typename
                    }
                partNumber
                baseCode
                prices {
                    currency
                    type
                    value
                    __typename
                }
                skuPriceRange {
                    sale {
                        value
                    }
                    retail {
                        value
                    }
                    __typename
                }
                name
                brand
                seo {
                    url
                    __typename

                }
                isPersonalizable
                isPassportEligible
                availability {
                    availabilityIndicator
                    deliveryDateType
                    displayEndDate
                    displayStartDate
                    earliestShipDate
                    latestShipDate
                    perishable
                    productDeliveryType
                    shipAlone
                    deliveryMessage
                    __typename
                }
                __typename
            }
            totalPages
            __typename
        }
    }

`
);

export default findCategoryById;
