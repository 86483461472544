/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React from 'react';
import mbpLogger from 'mbp-logger';
import { object, string } from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { getBrand } from '../../../../../state/ducks/App/ducks/Brand/Brand-Selectors';
import { getBestSellersId } from '../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import GraphqlBestSellerCollection from '../../Graphql404Error/Graphql404ErrorProductContainer';

const useStyles = makeStyles((theme) => ({
    noProductsText: {
        color: theme.palette.error.main,
        margin: '16px 0',
        fontSize: '14px',
        textAlign: 'center',
        fontWeight: 'bold',
    },
    bestSellerText: {
        color: theme.palette.common.black,
        margin: '15px',
        fontSize: '18px',
        textAlign: 'center',
        fontWeight: 'bold',
    },
}));

const ProductFallbackContainer = ({ brand, bestSellersId, callbackData }) => {
    const classes = useStyles();
    if (typeof window !== 'undefined') {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            message: 'Fallback Container rendered',
            pathname: window.location.pathname,
        });
    }
    return (
        <>
            <div className={classes.noProductsText}>There are currently no products available in this collection. Try your search again using the search box at the top of the page, or select from our best sellers below.</div>
            <div className={classes.bestSellerText}>Here are some of our best sellers...</div>
            <GraphqlBestSellerCollection brand={brand} categoryCode={bestSellersId} callbackData={callbackData} />
        </>
    );
};

const mapStateToProps = (state) => ({
    brand: getBrand(state),
    bestSellersId: getBestSellersId(state),
});

ProductFallbackContainer.propTypes = {
    brand: object.isRequired,
    bestSellersId: string.isRequired,
    callbackData: string.isRequired,
};
export default connect(mapStateToProps)(ProductFallbackContainer);
