/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Query } from '@apollo/client/react/components';
import { gql } from '@apollo/client';
import {
    object, shape, string, bool,
} from 'prop-types';
import mbpLogger from 'mbp-logger';
import { compose } from 'recompose';

import { GRAPHQL_ENV } from '../../../gql';
import * as app from '../../../../state/ducks/App';
import { getSCILoadingState } from '../../../../state/ducks/SCI/SCI-Selectors';
import CategoryPageSkeleton from '../GraphqlSkeletonComponents/CategoryPageSkeleton/CategoryPageSkeleton';
import { coreBrowsingPageViewSuccessTest, trackData } from '../../../helpers/tracking/common/commonTrackingHelpers';
import withPageView from '../../../helpers/tracking/hocs/withPageView';
import Graphql404ErrorCollectionContainer from './Graphql404ErrorCollectionContainer';

const brandSelectors = app.ducks.brand.selectors;
// multiple network calls are needed for the building structure because the error page is a category page but with some special copy from the 404 content page.
const Graphql404ErrorContainer = ({
    brand, page, SCIIsLoading,
}) => {
    const contentType = page?.contentType || 'template_error_page';
    const ERROR_PAGE_QUERY = gql`
            query ErrorPageQuery {
                findContentPage(brand: "${brand['domain-name']}", environment: "${GRAPHQL_ENV}", contentType: "${contentType}", url: "/404-page") {
                    content
                }
            }
        `;

    trackData({
        action: 'track_404_data',
    });

    // below no longer in use but preserved for ease of understanding previous flow
    // Clear is404. Otherwise system thinks all subsequent products displayed are unavailable.
    // if (typeof window !== 'undefined' && window.is404) window.is404 = false;
    return (
        <Query query={ERROR_PAGE_QUERY}>
            {({ loading, error, data }) => {
                if (loading && SCIIsLoading) {
                    return <CategoryPageSkeleton />;
                }

                if (loading) {
                    return null; // skeleton component
                }

                if (error) {
                    mbpLogger.logError({
                        error,
                        component: 'Graphql404ErrorContainer.js',
                        brand: brand.domain,
                        contentType,
                    });
                    return null;
                }

                if (!data?.findContentPage?.content?.entries?.length) {
                    mbpLogger.logError({
                        message: 'No entries returned for query',
                        component: 'Graphql404ErrorContainer.js',
                        brand: brand?.domain,
                        contentType,
                    });
                    return null;
                }

                return <Graphql404ErrorCollectionContainer brand={brand} pageData={data} />;
            }}
        </Query>
    );
};

Graphql404ErrorContainer.propTypes = {
    brand: object.isRequired,
    page: shape({
        contentType: string,
    }).isRequired,
    SCIIsLoading: bool,
};

Graphql404ErrorContainer.defaultProps = {
    SCIIsLoading: false,
};

const mapStateToProps = (state) => ({
    brand: brandSelectors.getBrand(state),
    SCIIsLoading: getSCILoadingState(state),
});

const enhance = compose(
    withPageView({
        pageViewSuccessTest: coreBrowsingPageViewSuccessTest,
    }),
    withRouter,
    connect(mapStateToProps),
);

export default enhance(Graphql404ErrorContainer);
