/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Query } from '@apollo/client/react/components';
import { gql } from '@apollo/client';
import { string, object } from 'prop-types';
import mbpLogger from 'mbp-logger';
import { makeStyles } from '@material-ui/core/styles';

import { GRAPHQL_ENV } from '../../../gql';
import { getBrand } from '../../../../state/ducks/App/ducks/Brand/Brand-Selectors';
import { getPresentationFamily } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';
import Graphql404ErrorProductContainer from './Graphql404ErrorProductContainer';
import ProductsFallbackContainer from '../GraphqlCommonComponents/GraphqlProductsFallbackContainer/ProductsFallbackContainer';
import useExperimentServiceAttributes from '../../../helpers/experimentService/useExperimentServiceAttributes';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '15px 0 0 0',
    },
    header: {
        WebkitFontSmoothing: 'antialiased',
        textAlign: 'center',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            paddingTop: '10px',
        },
    },
    oopsContainer: {
        lineHeight: '1.2',
        borderTop: `1px solid ${theme?.palette?.colorNeutral20}`,
        padding: '20px 0 20px 0',
    },
    oopsText: {
        color: theme?.palette?.colorNeutral70,
        fontFamily: 'Roboto, sans-serif',
        fontSize: '16px',
        letterSpacing: '.03em',
        textShadow: '1px 1px 1px rgba(0, 0, 0, 0.004)',
    },
    OopsText: {
        color: theme?.palette?.black,
        fontFamily: 'Roboto, sans-serif',
        fontSize: '28px',
        fontWeight: 'bold',
        // textShadow: '1px 1px 1px rgba(0, 0, 0, 0.004)',
    },
    break: {
        height: '12px',
        background: theme?.palette?.colorNeutral30,
        boxShadow: 'inset 0 1px 3px rgba(59,33,81,0.21), inset 0 1px 2px rgba(59,33,81,0.21)',
    },
    bestSellerContainer: {
        padding: '15px',
    },
    goodnewscontainer: {
        padding: '5px',
    },
    bestSellersText: {
        color: theme?.palette?.black,
        fontSize: '18px',
    },
    goodnewsText: {
        color: theme?.palette?.black,
        fontSize: '14px',
    },
    links: {
        padding: '5px',
    },
    linksText: {
        color: theme?.palette?.black,
        fontSize: '14px',
        textDecoration: 'underline',
    },
}));

const Graphql404ErrorCategoryContainer = ({
    url, collectionContentType, pageData,
}) => {
    const presentationFamily = useSelector(getPresentationFamily);
    const brand = useSelector(getBrand);
    const { targeting, context, isGraphqlTargetingEnabled  } = useExperimentServiceAttributes({ queryName: 'findCategoryPageByUrl' });
    const classes = useStyles();
    const ERROR_PAGE_QUERY = gql`
            query ErrorPageQuery${isGraphqlTargetingEnabled ? '($targeting: [Targeting])' : ''} {
              findCategoryPageByUrl(brand: "${brand['domain-name']}", environment: "${GRAPHQL_ENV}", url: "${url}", contentType: "${collectionContentType}"${isGraphqlTargetingEnabled ? ', targeting: $targeting' : ''}) {
                    brand
                    content
                }
            }
        `;

    const {
        collection_copy, return_link, collection_link, top_image, top_copy,
    } = pageData?.findContentPage?.content?.entries[0];

    return (
        <Query
            query={ERROR_PAGE_QUERY}
            variables={isGraphqlTargetingEnabled ? { targeting } : {}}
            context={context}
        >
            {({ loading, error, data }) => {
                if (loading) {
                    return <div style={{ height: '80vh' }} />; // prevent header and footer from snapping together
                }

                if (error) {
                    mbpLogger.logError({
                        appName: process.env.npm_package_name,
                        query: ERROR_PAGE_QUERY,
                        component: 'Graphql404ErrorCategoryContainer.js',
                        message: 'Error loading data from Graphql',
                        env: GRAPHQL_ENV,
                        error,
                    });
                    return null;
                }

                if (!data) {
                    mbpLogger.logWarning({
                        appName: process.env.npm_package_name,
                        query: ERROR_PAGE_QUERY,
                        component: 'Graphql404ErrorCategoryContainer.js',
                        message: 'No data returned for query',
                        env: GRAPHQL_ENV,
                    });
                }
                const code = data?.findCategoryPageByUrl?.content?.entries[0]?.code;
                return (
                    <Grid className={classes.container}>
                        {presentationFamily === 'flower'
                            ? (
                                <Grid className={classes.header}>
                                    <div className={classes.oopsContainer}>
                                        <span className={classes.oopsText}>{top_copy || null }</span>
                                    </div>
                                    <div className={classes.break} />
                                    <Grid className={classes.bestSellerContainer}>
                                        <span className={classes.bestSellersText}><strong>{collection_copy || null}</strong></span>
                                    </Grid>
                                </Grid>
                            )
                            : (
                                <Grid className={classes.header}>
                                    <Link to={return_link?.href || null}>
                                        {top_image?.url && <img src={top_image?.url || null} alt="topImage" />}
                                    </Link>
                                    <Grid className={classes.goodnewscontainer}>
                                        <span className={classes.goodnewsText}>{collection_copy || null}</span>
                                    </Grid>
                                    {collection_link?.title &&  (
                                        <Link to={collection_link?.href || null} className={classes.links}>
                                            <span className={classes.linksText}>{collection_link?.title || null}</span><br />
                                        </Link>
                                    )}
                                    {return_link?.title && (
                                        <Link to={return_link?.href || null}>
                                            <span className={classes.linksText}>{return_link?.title || null}</span>
                                        </Link>
                                    )}
                                </Grid>
                            )}
                        {code ? <Graphql404ErrorProductContainer brand={brand} categoryCode={code} pageData={pageData} /> : <ProductsFallbackContainer />}
                    </Grid>
                );
            }}
        </Query>
    );
};

Graphql404ErrorCategoryContainer.propTypes = {
    url: string.isRequired,
    collectionContentType: string.isRequired,
    pageData: object.isRequired,
};

export default withRouter((Graphql404ErrorCategoryContainer));
